<template>
  <div class="contact-us-page">
    <header-component class="header-fixed"></header-component>
    <div class="main-container">
    <iframe
      ref="iframe"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      src="https://mapiz.wufoo.com/embed/qj2yv7j1r10i0w/"
    >
      <a href="https://mapiz.wufoo.com/forms/qj2yv7j1r10i0w/">Fill out my Wufoo form!</a>
    </iframe>
    </div>
  </div>
</template>
<style lang="less" scoped>
.contact-us-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header-fixed{
    box-shadow: 0 1px 12px 0 rgba(51, 51, 51, 0.23);
  }
  .main-container{
    flex:1;
    padding:10px; 
  iframe {
    border: none;
    width: 100%;
    height:100%;
    flex:1;
    padding: 10px;
  }
  }
}
</style>

<script>
import HeaderComponent from "../components/Header";
export default {
  name: "ContactUsPage",
  components: { HeaderComponent }
};
</script>

