import axios from 'axios'
import { initServerError } from './errors'

export const timeout = 25000
export const baseURL = `${process.env.VUE_APP_API_URL}/api/v1/`
export const service = axios.create({
  baseURL: baseURL,
  timeout// 请求超时时间
})
service.interceptors.request.use((config) => {
  return config
}, function (error) {
  // 对请求错误做些什么
  // eslint-disable-next-line
  return Promise.reject(error)
})
// 通用响应拦截器
service.interceptors.response.use((response) => {
  return response
}, function (error) {
  return Promise.reject(initServerError(error))
})
export default service
