import {timeout} from './fetch'

/**
 * 100 普通服务器端错误，直接将错误信息弹框显示
 * 101 登录超时，跳转到登录页重新登录
 */

/**
 * 展示错误信息
 * @param error 错误
 */
export function showErrorMsg(error) {
    // 0 说明该错误不做任何处理，有可能是手动取消
    if (error.code === 0) {
        return
    }
    let {msg} = error
    if (!msg) {
        return
    }
    alert(msg)
}

/**
 *  初始化服务器端返回错误信息 (服务器端错误统一1XX)
 * @param error 服务器返回错误信息
 * @returns {{code: number, msg}} 封装后错误信息
 */
export function initServerError(error) {
    const {response} = error
    let errorCode = 200
    if (response === undefined) {
        // 应该是网络问题
        return {
            code: errorCode,
            msg: error.message
        }
    }
    let msg = error.message
    let responseData
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'developmentBuild') {
            alert(`${error.config.url}(${error.config.method}) timeout (${timeout}ms)`)
        } else {
            alert(`Connection to server timed out. Please try again later.`)
        }
    } else {
        errorCode = response.data.error_code
        msg = response.data.message
        responseData = response.data
    }
    let errorMsg = {
        code: 100,
        errorCode,
        msg,
        responseData
    }
    // 判断如果是401错误 直接跳转至登录页
    if (response.status === 401 && !response.config.ignore401) {
        errorMsg.code = 101
    } else {
        errorMsg.code = 100
    }
    // 判断如果 11403 说明是未登录则不做弹出提醒
    if (errorCode === 11403) {
        errorMsg.code = ''
    }
    return errorMsg
}

/**
 * 初始前端验证返回错误信息 (客户端错误统一2XX)
 * @param errorMsg 需要提示给用户的错误信息
 * @returns {{code: number, msg: *}} 封装后错误信息
 */
export function initValidate({msg}) {
    return {
        code: 200,
        msg: msg
    }
}

/**
 * initValidate 的promise版
 * @param errorMsg 需要提示给用户的错误信息
 * @returns {Promise<never>} 封装后错误信息
 */
export function initValidateByPromise(errorMsg) {
    return Promise.reject(initValidate({msg: errorMsg}))
}
