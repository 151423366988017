<template>
  <div class="price-page">
    <header-component class="header-fixed" type="price"></header-component>
    <div class="container price-container">
      <div class="row">
        <div class="col-md-12">
          <div class="web-list">
            <div id="priceContainer">
              <div class="plan-row price-popular-container">
                <div class="plan-column"></div>
                <div
                  style="border-bottom: 0 !important;"
                  class="plan-column"
                  :class="{ 'plan-popular': item.mostPopular }"
                  :key="item.name"
                  v-for="item in priceList"
                >
                  <template v-if="item.mostPopular"
                    >Most Popular</template
                  >
                </div>
              </div>
              <div class="plan-row price-type-title">
                <div class="plan-column"></div>
                <div
                  class="plan-column"
                  :key="item.name"
                  v-for="item in priceList"
                >{{ item.name }}</div>
              </div>
              <div class="plan-row price-detail">
                <div class="plan-column"></div>
                <div
                  class="plan-column"
                  :key="item.name"
                  v-for="(item, index) in priceList"
                  :class="{
                    'plan-popular': item.mostPopular,
                    'no-right-border': popularBeforeStyle(item, index)[
                      'no-right-border'
                    ],
                  }"
                >
                  <div class="plan-price" v-show="item.currency !== 'USD'">
                    <div
                      class="num"
                      style="position:relative;bottom:-4px;line-height:24px"
                    >
                      <div
                        style="font-size:20px;position:relative;bottom:10px;"
                      >{{ item.currency }}</div>
                      <div style="font-size:32px;">{{ item.price }}<span class="mo">/m</span>
                      </div>
                    </div>
                  </div>
                  <div v-show="item.currency === 'USD'" class="plan-price">
                    <span class="symbol">$</span>
                    <span class="num">{{ item.price }}</span>
                    <span class="mo">/m</span>
                  </div>
                  <div class="sub-btn" @click="itemClick">Select</div>
                </div>
              </div>
              <div
                class="plan-row limit-tip"
                v-for="permission in permissionList"
                :key="permission.key"
              >
                <div class="plan-column plan-title">{{ permission.title }}</div>
                <template v-for="(price, index) in priceList">
                  <div
                    :key="price.name"
                    class="plan-column"
                    :class="columnStyle(permission, price, index)"
                  >{{ price[permission.key] }}</div>
                </template>
              </div>
              <div class="plan-row price-popular-bottom-container">
                <div class="plan-column"></div>
                <div
                  class="plan-column"
                  :class="{ 'plan-popular': item.mostPopular }"
                  :key="item.name"
                  v-for="item in priceList"
                ></div>
              </div>
            </div>
            <div
              class="bottom-des"
              v-show="showPriceTip"
              :style="{
                'padding-right': `${(6 - priceList.length) * 180 + 20}px`,
              }"
            >
              All Prices are in USD
            </div>
          </div>
          <div class="app-list">
            <div
              class="price-package-item"
              :key="item.name"
              v-for="item in priceList"
              @click="itemClick"
            >
              <div class="first">
                <span class="type">
                  <div>{{ item.name }}</div>
                  <div class="most-popular" v-if="item.mostPopular">
                    Most Popular
                  </div>
                </span>
                <div class="price">
                  <div class="price-text" v-if="item.price > 0">
                    {{ item.priceText }}
                  </div>
                </div>
              </div>
              <div class="des">
                <div v-if="item.price !== 0">Everything in Free Plan, and</div>
                <div style="padding: 3px 0">
                  <div
                    v-for="descItem in item.descList"
                    :key="descItem.text"
                    class="des-item"
                    :class="{ forbid: descItem.forbid }"
                  >
                    <div class="detail-icon" v-if="descItem.icon">
                      {{ descItem.icon }}
                    </div>
                    <div class="detail-text">{{ descItem.text }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-des">All Prices are in USD</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="member-des-container">
            <div class="member-des-title">
              Instagram official limitations apply to all Apphi plans:
            </div>
            <div class="des-item" :key="text" v-for="text in des2">
              <div>
                <div class="icon"></div>
              </div>
              <div>{{ text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row question-container">
        <div class="col-md-12">Frequently asked questions</div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="member-des-container">
            <div class="member-des-title">What’s Member?</div>
            <div class="des-item" :key="text" v-for="text in memberDes">
              <div>
                <div class="icon"></div>
              </div>
              <div>{{ text }}</div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-12">
          <div class="trusted-container">
            Trusted by publishers and businesses
          </div>
        </div>
      </div>
    </div>
    <div class="logo-list">
      <img src="../../public/logos/logo_stregis_b@3x.png" />
      <img src="../../public/logos/logo_sheraton_b@3x.png" />
      <img src="../../public/logos/logo_yelp_b@3x.png" />
      <img src="../../public/logos/logo_home_b@44x.png" />
      <img src="../../public/logos/logo_davidoff_b@3x.png" />
      <img src="../../public/logos/logo_levis_b@3x.png" />
      <img src="../../public/logos/logo_clips_b@3x.png" />
      <img src="../../public/logos/logo_pubity_b@3x.png" />
    </div>
    <bottom-info-component></bottom-info-component>
  </div>
</template>

<script>
import axios from "../utils/fetch";
import { getDevice } from "../utils/utils";
import BottomInfoComponent from "../components/BottomInfo";
import HeaderComponent from "../components/Header";
import { showErrorMsg } from "../utils/errors";

export default {
  name: "PricePage",
  components: { HeaderComponent, BottomInfoComponent },
  data() {
    return {
      planList: [], // plan数组
      memberDes: [
        "Member can only manage the social accounts you assigned.",
        "Member will not know the social account password you assigned.",
        "Member cannot make purchases or access your payment information.",
        "You or member can review and approve the schedules before they get posted.",
      ],
      des2: [
        "Story cannot auto post",
        "Creator account cannot auto post",
        "Limited to 25 auto post within 24 hours",
        "Video cannot tag people",
      ],
    };
  },
  computed: {
    permissionList() {
      if (this.planList && this.planList.length > 0) {
        return this.planList[0].permissionList;
      }
      return [];
    },
    // 2019.12.14 显示美金支付标识
    showPriceTip() {
      const first = this.planList[0];
      
      return first.currency === "USD";
    },
    /** 计算价格列表 */
    priceList() {
      return this.planList;
    },
  },
  methods: {
    /**
     * 点击后跳入对应主页
     */
    itemClick() {
      const device = getDevice();
      if (device === "web") {
        window.location.href = "https://desktop.apphi.com";
      } else {
        window.location.href = "https://m.apphi.com";
      }
    },
    /**
     * 计算most popular 之前的样式
     */
    popularBeforeStyle(price, index) {
      let result = {};
      const planIndex = Number(index);
      // 如果发现价格的一下是most popular则下一行右侧没有线
      if (planIndex !== this.planList.length - 1) {
        // 最后一个不校验
        const nextIsPopular = this.planList[planIndex + 1].mostPopular;
        if (nextIsPopular) {
          result["no-right-border"] = true;
        }
      }
      return result;
    },
    /**
     * 计算列的样式
     */
    columnStyle(permission, price, index) {
      let result = {};
      if (price[permission.key] !== "✗") {
        result.have = true;
        result["no-have"] = false;
      } else {
        result.have = false;
        result["no-have"] = true;
      }
      // ✓ 或者 ✗ 则加粗
      if (price[permission.key] === "✓" || price[permission.key] === "✗") {
        result["bold"] = true;
      }
      // mostPopular 显示特殊样式
      if (price.mostPopular) {
        result[" plan-popular"] = true;
      }
      const style = this.popularBeforeStyle(price, index);
      result = { ...result, ...style };
      return result;
    },
  },
  async created() {
    this.$loading.show();
    try {
      const { data } = await axios.get("plans", {
        params: {
          from: "desktop",
        },
      });
      this.planList = data.map((vo) => {
        // 计划名称
        const name = vo.name;
        // 计划描述信息
        let desc = vo.desc;
        // 该计划中最低价格
        const price = vo.best_price_per_month;
        // 是否为most popular
        const mostPopular = vo.most_popular;
        // 2019.12.14 plan增加货币
        const currency = vo.currency;
        const priceText = vo.best_display_price_per_month;
        // ✓ ✗
        const desArr = desc.split("\n");
        let result = [];
        desArr.forEach((row) => {
          const iconIndex = [...row].findIndex((r) => r === "✓" || r === "✗");
          result.push({
            icon: row.substr(0, iconIndex + 1).trim(),
            // eslint-disable-next-line
            text: row
              .substr(iconIndex + 1) // eslint-disable-next-line
              .replace(/  /g, "")
              .trim(), // 玄学过滤 千万不要动，否则pt语言会有问题
            forbid: [...row].findIndex((r) => r === "✗") !== -1,
          });
        });
        let item = {
          name,
          price,
          mostPopular,
          currency,
          priceText,
        };
        // 2019.12.20 对接新的pc描述文件
        const permissionList = [];
        vo.desc_as_list.forEach((desc) => {
          for (let key in desc) {
            item[key] = desc[key];
            permissionList.push({
              key,
              title: key,
            });
          }
        });
        item.permissionList = permissionList;
        item.descList = result;
        return item;
      });
    } catch (e) {
      showErrorMsg(e);
    } finally {
      this.$loading.hide();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "../assets/style/theme.less";

.price-page {
  .header-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-content: center;
  }

  .price-container {
    margin-top: 100px;
    @media (min-width: 800px) {
      margin-top: 174px;
    }
  }

  .web-list {
    width: 1330px;
    // 宽度过小则隐藏
    @media screen and (max-width: 1200px) {
      & {
        display: none;
      }
    }

    #priceContainer {
      border-radius: 5px;
    }

    #priceContainer .plan-row {
      display: table;
    }

    #priceContainer .plan-row .plan-column {
      display: table-cell;
      width: 180px;
      box-sizing: border-box;
      white-space: pre-wrap;
      &:first-child {
        width: 230px;
      }
    }

    #priceContainer .plan-row .plan-column.no-right-border {
      border-right: none !important;
    }

    #priceContainer .plan-row.price-type-title .plan-column {
      border-right: 1px solid white;
      height: 50px;
      line-height: 50px;
      background: #e72955;
      color: white;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }

    #priceContainer .plan-row.price-detail .plan-column {
      vertical-align: middle;
      text-align: center;
      height: 153px;
      border-right: 1px solid rgba(190, 190, 190, 0.5);
      border-bottom: 1px solid rgba(190, 190, 190, 0.5);
    }

    #priceContainer .plan-row.price-detail .plan-column .plan-price {
      position: relative;
    }

    #priceContainer .plan-row.price-detail .plan-column .plan-price .symbol {
      font-size: 24px;
      position: relative;
      top: -8px;
    }

    #priceContainer .plan-row.price-detail .plan-column .plan-price .num {
      font-size: 36px;
    }

    #priceContainer .plan-row.price-detail .plan-column .plan-price .mo {
      font-size: 20px;
    }

    #priceContainer .plan-row.limit-tip:hover .plan-column {
      background: rgba(190, 190, 190, 0.1);
    }

    #priceContainer .plan-row.limit-tip:hover .plan-column.plan-popular {
      background: rgba(221, 31, 75, 1) !important;
    }

    #priceContainer .plan-row.limit-tip .plan-column {
      height: 47px;
      vertical-align: middle;
      text-align: center;
      font-size: 14px;
      border-right: 1px solid rgba(190, 190, 190, 0.5);
      border-bottom: 1px solid rgba(190, 190, 190, 0.5);
      padding: 5px 10px;
    }

    #priceContainer .plan-row.limit-tip .plan-column.plan-title {
      text-align: right;
    }

    #priceContainer .plan-row.limit-tip .plan-column:first-child {
      border-left: 1px solid rgba(190, 190, 190, 0.5);
    }

    #priceContainer .plan-row.limit-tip .plan-column.bold {
      font-weight: 900;
    }

    #priceContainer .plan-row.limit-tip .plan-column.have {
      color: #e72955;
    }

    #priceContainer .plan-row.limit-tip .plan-column.no-have {
      color: #8c8c8c;
    }

    #priceContainer .plan-row.price-popular-container .plan-column {
      border-radius: 5px 5px 0 0;
      font-size: 20px;
      background: transparent;
      height: 50px;
      line-height: 50px;
      border-right: 1px solid white;
      text-align: center;
      color: #f7e71b !important;
      font-weight: 500;
    }

    #priceContainer .plan-row.price-popular-bottom-container .plan-column {
      border-radius: 0 0 5px 5px;
      height: 8px;
    }

    #priceContainer .plan-row.price-type-title .plan-column:first-child {
      background: transparent;
    }

    #priceContainer .plan-row.price-type-title .plan-column:nth-child(2) {
      border-radius: 5px 0 0 0;
    }

    #priceContainer .plan-row.price-type-title .plan-column:last-child {
      border-radius: 0 5px 0 0;
      border-right-width: 0;
    }

    .sub-btn {
      width: 150px;
      height: 32px;
      line-height: 32px;
      margin-top: 24px;
      border-radius: 4px;
      color: white;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      background: #e72955;
      box-sizing: content-box !important;
      border: 2px solid #e72955;
    }

    .plan-popular.plan-column {
      border-radius: 0;
      background: #e72955 !important;
      color: white !important;
      border-right: 1px solid white !important;
      border-bottom: 1px solid white !important;
    }

    .plan-popular .sub-btn {
      border: solid 2px white;
      color: white !important;
      background: transparent;
    }

    .sub-btn:hover {
      opacity: 0.7;
    }

    .bottom-des {
      font-size: 14px;
      color: rgb(140, 140, 140);
      margin-top: 40px;
      text-align: right;
      padding-right: 20px;
    }
  }

  .app-list {
    // 宽度过小则隐藏
    @media screen and (min-width: 1200px) {
      & {
        display: none;
      }
    }
    font-family: AppleSystemUIFont, -apple-system, BlinkMacSystemFont,
      sans-serif;
    -webkit-font-smoothing: auto;
    letter-spacing: normal;
    .bottom-des {
      font-size: 14px;
      color: rgb(140, 140, 140);
      text-align: right;
      position: relative;
      top: 20px;
    }

    .price-package-item {
      padding: 10px;
      border-radius: 6px;
      color: white;
      margin-top: 10px;
      width: 100%;
      box-sizing: border-box;
      border: 2px solid transparent;
      position: relative;
      text-align: initial;
      cursor: pointer;
      background: #da16ae;
      &:hover {
        opacity: 0.7;
      }

      .first {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .type {
          font-size: 20px;
          font-weight: 600;
          line-height: 1;
          display: flex;
          align-items: center;

          .most-popular {
            font-size: 13px;
            background: #f7e71b;
            border-radius: 2px;
            color: @theme-color;
            font-weight: bolder;
            margin: 0 10px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            padding: 0 15px;
          }
        }

        .price {
          font-weight: 600;
          font-size: 20px;
          line-height: 1;
          flex: 1;

          .price-text {
            display: flex;
            justify-content: flex-end;
          }

          .your-plan {
            font-size: 24px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .des {
        margin-top: 10px;
        font-size: 15px;
        line-height: 20px;

        .des-item {
          display: flex;
          align-items: flex-start;
          font-size: 15px;
          font-weight: 500;
          &.forbid {
            color: rgba(255, 255, 255, 0.7);
          }
          &.free {
            .detail-text {
              font-weight: normal;
            }
          }

          .detail-icon {
            font-family: AppleSystemUIFont, -apple-system, BlinkMacSystemFont,
              sans-serif;
            font-weight: 900;
            margin-right: 10px;
          }

          .detail-text {
            font-weight: 500;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .question-container {
    font-size: 24px;
    margin-top: 108px;
    @media screen and (max-width: 1200px) {
      margin-top: 87px;
    }
  }

  .member-des-container {
    padding: 30px;
    font-size: 16px;
    background: #f9f9f9;
    margin-top: 30px;

    .member-des-title {
      margin-bottom: 10px;
      font-size: 18px;
    }

    .des-item {
      display: flex;
      line-height: 20px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        width: 6px;
        height: 6px;
        background: #000;
        border-radius: 50%;
        position: relative;
        top: 7px;
        margin: 0 6px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .trusted-container {
    font-size: 24px;
    color: #323232;
    text-align: center;
    width: 100%;
    margin-top: 80px;
    font-weight: 500;
    @media (max-width: 767px) {
      line-height: 28px;
    }
  }

  .logo-list {
    text-align: center;
    margin: 20px 0 100px 0;
    width: 100%;
    padding: 0 20px;

    img {
      margin: 0 15px 10px 15px;
      height: 54px;
    }
  }
}
</style>
