<template>
    <div class="login-page">
        <div class="web-container">
            <div class="apphi-logo"></div>
            <div class="login-container">
                <div class="left-container">
                    <div class="title-container">
                        Trusted by top brands & publishers
                    </div>
                    <div class="login-logo-container center" style="margin-top: 37px">
                        <img src="../../public/logos/logo_stregis@3x.png" style="height: 26px">
                    </div>
                    <div class="login-logo-container center" style="margin-top: 32px">
                        <img src="../../public/logos/logo_sheraton@3x.png" style="height: 32px">
                    </div>
                    <div class="login-logo-container" style="margin-top: 36px">
                        <img src="../../public/logos/logo_yelp@3x.png" style="height: 32px">
                        <img src="../../public/logos/logo_home@3x.png" style="height: 32px">
                        <img src="../../public/logos/logo_davidoff@3x.png" style="height: 32px">
                    </div>
                    <div class="login-logo-container" style="margin-top: 36px">
                        <img src="../../public/logos/logo_levis@3x.png" style="height: 28px">
                        <img src="../../public/logos/logo_clips@3x.png" style="height: 32px">
                        <img src="../../public/logos/logo_pubity@3x.png" style="height: 32px">
                    </div>
                </div>
                <div class="login-right-container">
                    <div class="form-title">{{formTitle}}</div>
                    <template v-if="$route.query.type === 'create'">
                        <div class="form-item">
                            <input v-model="createForm.email" autocomplete="off" placeholder="Email"/>
                        </div>
                        <div class="form-item">
                            <input v-model="createForm.confirm" autocomplete="off" placeholder="Confirm Email Again"/>
                        </div>
                        <div class="form-item">
                            <input v-model="createForm.password" autocomplete="new-password" type="password"
                                   placeholder="Password"/>
                        </div>
                        <div class="button login" @click="registerClick">Sign Up</div>
                        <div class="tip create">By joining, you agree on our <span class="click"
                                                                                   @click="termsPage">Terms</span> and
                            <span class="click" @click="privacyPage">Privacy Policy</span></div>
                        <div class="create-login-tip">
                            <span>Already have an account? </span>
                            <span class="login-click" @click="toLoginPage">Login here</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="form-item">
                            <input v-model="loginForm.email" autocomplete="off" placeholder="Email"/>
                        </div>
                        <div class="form-item">
                            <input v-model="loginForm.password" type="password" autocomplete="new-password"
                                   placeholder="Password"/>
                        </div>
                        <div class="button login" @click="loginClick">Login</div>
                        <div class="tip password">Forgot password?</div>
                        <div class="button create-free" @click="toCreatePage">Create Account For Free</div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '../utils/fetch'
    import {Base64 as base64} from 'js-base64'
    import {toTermsPage, toPrivacyPage} from "../services/common";
    import {showErrorMsg} from "../utils/errors";

    export default {
        name: 'PricePage',
        data() {
            return {
                createForm: {
                    email: '',
                    confirm: '',
                    password: ''
                },
                loginForm: {
                    email: '',
                    password: ''
                }
            }
        },
        computed: {
            formTitle() {
                if (this.$route.query.type === 'login') {
                    return 'Login'
                } else {
                    return 'Create Account'
                }
            }
        },
        methods: {
            /**
             * 登录按钮点击事件
             */
            async loginClick() {
                if(!this.loginForm.email && !this.loginForm.password){
                    alert('Cannot be empty.')
                    return
                }
                this.$loading.show()
                try {
                    const encode = base64.encode(`${this.loginForm.email}:${this.loginForm.password}`)
                    const {data} = await axios.get('users/self', {
                        headers: {
                            'Authorization': `Basic ${encode}`
                        },
                        params: {
                            with_stripe: false
                        }
                    })
                    window.open(`${process.env.VUE_APP_DESKTOP_URL}/#/?auth=${data.auth}`, '_blank')
                } catch (e) {
                    showErrorMsg()
                } finally {
                    this.$loading.hide()
                }
            },
            /**
             * 注册点击事件
             */
            async registerClick() {
                if(!this.createForm.email && !this.createForm.confirm && !this.createForm.password){
                    alert('Cannot be empty.')
                }
                // 2次验证邮箱不相同
                if (this.createForm.email !== this.createForm.confirm) {
                    alert('Both emails must be the same.')
                    return
                }
                // 密码大于6位
                if (this.createForm.password.length < 6) {
                    alert('Password must have minimum 6 characters.')
                    return
                }
                this.$loading.show()
                try {
                    const {data} = await axios.post('/users', {
                        email: this.createForm.email,
                        password: this.createForm.password
                    })
                    window.open(`${process.env.VUE_APP_DESKTOP_URL}/#/?auth=${data.auth}`, '_blank')
                } catch (e) {
                    showErrorMsg()
                } finally {
                    this.$loading.hide()
                }
            },
            /**
             * 去登录页面
             */
            toLoginPage() {
                this.$router.push({name: 'LoginPage', query: {type: 'login'}})
            },
            /**
             * 去注册页面
             */
            toCreatePage() {
                this.$router.push({name: 'LoginPage', query: {type: 'create'}})
            },
            /**
             * 去terms 页面
             */
            termsPage() {
                toTermsPage()
            },
            /**
             * 去privacy policy 页面
             */
            privacyPage() {
                toPrivacyPage()
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    @import "../assets/style/theme.less";

    .login-page {
        width: 100%;
        min-height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../public/invite/login_bg@3x.png");
        background-attachment: fixed;
        display: flex;
        align-items: center;
        justify-content: center;

        .web-container {

            .apphi-logo {
                height: 44px;
                position: absolute;
                left: 47px;
                top: 43px;
                background-image: url(../../public/invite/apphi@3x.png);
                width: 116px;
                background-size: contain;
            }

            .login-container {
                width: 732px;
                display: flex;

                .left-container {
                    width: 274px;
                    background-color: rgba(255, 255, 255, 0.1);
                    border-radius: 8px 0 0 8px;

                    .login-logo-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 29px;

                        &.center {
                            justify-content: center;
                        }
                    }

                    .title-container {
                        font-size: 18px;
                        margin-top: 55px;
                        text-align: center;
                        color: white;
                    }
                }

                .login-right-container {
                    flex: 1;
                    background: white;
                    border-radius: 0 8px 8px 0;
                    padding: 0 37px;
                    height: 426px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .form-title {
                        font-size: 28px;
                        color: #4a4a4a;
                        text-align: center;
                        margin-bottom: 28px;
                    }

                    .form-item {
                        height: 55px;
                        border-bottom: 1px solid rgba(210, 210, 210, 0.31);

                        input {
                            background: transparent;
                            border: 0;
                            height: 55px;
                            font-size: 16px;
                            text-align: center;
                            width: 100%;
                            outline: none;
                            box-sizing: border-box;
                            vertical-align: middle;
                        }

                    }
                }
            }

            .tip {
                color: #8c8c8c;
                font-size: 12px;
                margin-top: 15px;

                &.password {
                    text-align: right;
                }

                &.create {
                    text-align: center;
                }

                .click {
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }

            .create-login-tip {
                font-size: 14px;
                margin-top: 20px;
                text-align: center;

                .login-click {
                    color: @theme-color;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        opacity: .7;
                    }
                }
            }

            .button {
                height: 53px;
                background-color: @theme-color;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }

                &.login {
                    margin-top: 20px;
                }

                &.create-free {
                    border-radius: 4px;
                    border: solid 2px @theme-color;
                    color: @theme-color;
                    background: transparent;
                    margin-top: 20px;
                }
            }
        }
    }
</style>
